import { createSlice } from "@reduxjs/toolkit";


interface IInitialStateProps  {
    isRefresh:boolean
}

const initialState:IInitialStateProps = {
    isRefresh: true,
}


export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setIsRefresh: (state, { payload }) => {
            state.isRefresh = payload;
        },

    }


})

export const { setIsRefresh } = companySlice.actions;

export const companySelector = (state: { company: IInitialStateProps }) => state.company;