import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    signData: {
        partnerVoen: '',
        partnerFin: '',
        companyVoen: '',
        companyName: '',
        partnerName: '',
        document: {
            file:'',
            fileContent: {
                header: '',
                body: '',
                footer: ''
            }
        }
    },
    isSign: false
}

export const partnerFindSlice = createSlice({
    name: 'partnerFind',
    initialState,
    reducers: {
        fillSignData: (state, { payload }) => {
            state.signData = { ...payload }
            state.isSign = true;
            return state;
        },
    }

})

export const { fillSignData } = partnerFindSlice.actions;

export const partnerFindSelector = (state: { partnerFind: typeof initialState }) => state.partnerFind;