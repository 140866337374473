import { useLayoutEffect,useState } from "react";
import { Router } from "react-router-dom";

export const CustomRouter = ({ history, ...props }) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location
    });
  
    useLayoutEffect(() => history.listen(setState), [history]);
  
    return (
      <Router
        {...props}
        // basename={basename}
        location={state.location}
        navigationType={state.action}
        navigator={history}

      />
    );
  };