import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from "static";


export const PrivateRoute = () => {
const auth = localStorage.getItem('ExternalAgentToken');


    return auth ? <Outlet /> : <Navigate to={ROUTES.PARTNER_MAIN_LOGIN} />;

}
export const LoginedRoute = () => {
    const auth = localStorage.getItem('ExternalAgentToken');

    return auth ? <Navigate to={ROUTES.MAIN} /> : <Outlet />;

}