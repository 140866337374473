
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider, Empty, message } from 'antd';
import moment from 'moment';

import App from 'App';
import store from 'store';
import { CustomRouter } from "routes/CustomRouter";
import { history } from 'helpers';
import az_AZ from 'antd/lib/locale/az_AZ';

import '@react-pdf-viewer/core/lib/styles/index.css';
import 'moment/locale/az';
import 'antd/dist/antd.min.css';
import 'global.scss';
import 'animate.css';

moment.locale('az');



Sentry.init({
  dsn: "https://91fa56634d1d41eea4a3d02cdbb3e80a@sentry.pasha-life.az/11",
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
  debug: import.meta.env.VITE_SENTRY_DEBUG,

});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store} >
      <CustomRouter history={history}>
        <ConfigProvider
          renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Məlumat yoxdur" />}
          locale={az_AZ}
        >
          {message.config({
            duration: 5,
            maxCount: 3
          })}
          <App key={az_AZ} />

        </ConfigProvider>
      </CustomRouter>
    </Provider>
  </>
);


