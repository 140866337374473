import { ReactNode } from "react";
import { message } from "antd";
import uniqid from "uniqid";

import { ErrorMsgIcon, InfoMsgIcon, SuccessMsgIcon, WarningMsgIcon } from "components/Icons";

import "./index.scss"

const onClose = (key: string) => {
    message.destroy(key)
}

const errorMsg = (errors: string[] | string) => {
    const key = uniqid()
    let errorList: ReactNode;
    let messageList = '';
    if (Array.isArray(errors)) {
        if (errors.length > 1) {
            errorList = errors.map((error, index) => {
                return (
                    <li key={index} >{error}</li>
                )
            })
            messageList = 'message-list'
        } else {
            errorList = errors[0];
        }
    } else {
        errorList = errors;
    }

    message.error({
        content: errorList,
        className: `ALERT_MESSAGE_BOX error ${messageList}`,
        icon: <ErrorMsgIcon />,
        onClick: () => onClose('error' + key),
        key: 'error' + key
    })

}

const successMsg = (text = 'Əməliyyat uğurla yerinə yetirildi!') => {
    message.success({
        content: text,
        className: 'ALERT_MESSAGE_BOX success',
        icon: <SuccessMsgIcon />,
        onClick: () => onClose('success'),
        key: 'success'
    })
}

const infoMsg = (text: string) => {
    message.success({
        content: text,
        className: 'ALERT_MESSAGE_BOX info',
        icon: <InfoMsgIcon />,
        onClick: () => onClose('info'),
        key: 'info'
    })
}

const warningMsg = (text: string) => {
    message.success({
        content: text,
        className: 'ALERT_MESSAGE_BOX warning',
        icon: <WarningMsgIcon />,
        onClick: () => onClose('warning'),
        key: 'warning'
    })
}
export {
    errorMsg,
    successMsg,
    infoMsg,
    warningMsg
}