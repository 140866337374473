import { configureStore } from "@reduxjs/toolkit";

import { userSlice } from "./UserSlice";
import { succesRegisterSlice } from "./SuccessRegisterSlice";
import { companySlice } from "./CompanySlice";
import { partnerFindSlice } from "./PartnerFindSlice";
import { notificationSlice } from "./NotificationSlice";
import { forgotPasswordSlice } from "./ForgotPasswordSlice";


export default configureStore({
    reducer:{
        user:userSlice.reducer,
        successRegister:succesRegisterSlice.reducer,
        company:companySlice.reducer,
        partnerFind:partnerFindSlice.reducer,
        notification:notificationSlice.reducer,
        forgotPassword:forgotPasswordSlice.reducer
    }
})