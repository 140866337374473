import Icon from '@ant-design/icons';

const SuccessMsgSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.5018 4.44254C19.8096 4.71963 19.8346 5.19385 19.5575 5.50173L11.8199 14.0991C10.8454 15.1819 9.19549 15.3168 8.05793 14.4068L4.53151 11.5857C4.20806 11.3269 4.15562 10.8549 4.41438 10.5315C4.67313 10.208 5.1451 10.1556 5.46855 10.4144L8.99497 13.2355C9.51204 13.6492 10.262 13.5878 10.705 13.0956L18.4426 4.49828C18.7197 4.1904 19.1939 4.16544 19.5018 4.44254Z" fill="#06C270" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.61424 16.3569C5.96943 16.1438 6.43012 16.2589 6.64323 16.6141C6.93128 17.0942 8.09726 18.25 10.0001 18.25C11.903 18.25 13.0689 17.0942 13.357 16.6141C13.5701 16.2589 14.0308 16.1438 14.386 16.3569C14.7412 16.57 14.8563 17.0307 14.6432 17.3859C14.1313 18.2391 12.5155 19.75 10.0001 19.75C7.48469 19.75 5.86894 18.2391 5.35699 17.3859C5.14388 17.0307 5.25906 16.57 5.61424 16.3569Z" fill="#06C270" />
    </svg>

)
const ErrorMsgSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5563 20.25 12 20.25Z" fill="#FF3B30" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V15C12.75 14.5858 12.4142 14.25 12 14.25C11.5858 14.25 11.25 14.5858 11.25 15V16C11.25 16.4142 11.5858 16.75 12 16.75ZM12 13.25C12.4142 13.25 12.75 12.9142 12.75 12.5V8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V12.5C11.25 12.9142 11.5858 13.25 12 13.25Z" fill="#FF3B30" />
    </svg>

)
const InfoMsgSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75Z" fill="#3366FF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V9C12.75 9.41421 12.4142 9.75 12 9.75C11.5858 9.75 11.25 9.41421 11.25 9V8C11.25 7.58579 11.5858 7.25 12 7.25ZM12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z" fill="#3366FF" />
    </svg>

)
const WarningMsgSVG = () => (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 7V12.0655C19 13.917 18.1452 15.6649 16.6836 16.8016L13.6836 19.1349C11.517 20.8201 8.48302 20.8201 6.31636 19.1349L3.31636 16.8016C1.85484 15.6649 1 13.917 1 12.0655V7C1 3.68629 3.68629 1 7 1H13C16.3137 1 19 3.68629 19 7Z" stroke="#FFBA3C" strokeWidth="1.5" strokeLinejoin="round" />
        <path d="M10 8V13" stroke="#FFBA3C" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M10.75 5.5C10.75 5.91421 10.4142 6.25 10 6.25C9.58579 6.25 9.25 5.91421 9.25 5.5C9.25 5.08579 9.58579 4.75 10 4.75C10.4142 4.75 10.75 5.08579 10.75 5.5Z" fill="#FFBA3C" />
    </svg>
)

export const SuccessMsgIcon = (props) => <Icon component={SuccessMsgSVG} {...props} />;
export const ErrorMsgIcon = (props) => <Icon component={ErrorMsgSVG} {...props} />;
export const InfoMsgIcon = (props) => <Icon component={InfoMsgSVG} {...props} />;
export const WarningMsgIcon = (props) => <Icon component={WarningMsgSVG} {...props} />;

