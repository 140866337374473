import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    signData: {
        userId: "",
        emailMask: "",
        phoneNumberMask: "",
        userName: "",
        otpCode: "",
        twoFactorTypeId: ""
    },
    isSign: false
}

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        fillData: (state, { payload }) => {
            state.signData = { ...state.signData, ...payload }
            state.isSign = true;
            return state;
        },
    }

})

export const { fillData } = forgotPasswordSlice.actions;

export const forgotPasswordSelector = (state: { forgotPassword: typeof initialState }) => state.forgotPassword;