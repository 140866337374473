export function cleanCache(version: string) {
    const version_localStorage = localStorage.getItem("version");
    if (version_localStorage !== version) {
        if ("caches" in window) {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        localStorage.clear();
        localStorage.setItem("version", version);
    }
}