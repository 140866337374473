
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IUserStateProps {
    isAuth: boolean;
    userData: {
        id: string | null;
        email: string | null;
        name: string | null;
        phone: string | null;
        partnerId: string | null;
    };
}
interface Payload {
    identityUserId: string;
    emails: { email: string; isDefault: boolean }[];
    name: string;
    phoneNumber: { phoneNumber: string; isDefault: boolean }[];
    partnerId: string;
}

const initialState: IUserStateProps = {
    isAuth: false,
    userData: {
        id: null,
        partnerId: null,
        email: null,
        name: null,
        phone: null
    },
}



export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        loginFilledData: (state, { payload }) => {
            const { id, partnerId, name, phone, email } = payload
            state.isAuth = true;
            state.userData = {
                id,
                partnerId,
                email,
                name,
                phone
            }
            return state;
        },

        refreshFilledData: (state, action: PayloadAction<Payload>) => {
            const payload = action.payload;
            if (payload) {
                state.isAuth = true;
                state.userData = {
                    id: payload.identityUserId,
                    email: payload.emails.find((email) => email.isDefault)?.email || null,
                    name: payload.name,
                    phone: payload.phoneNumber.find((phone) => phone.isDefault)?.phoneNumber || null,
                    partnerId: payload.partnerId,
                };
            }
        }
    }

})

export const { loginFilledData, refreshFilledData } = userSlice.actions;

export const userSelector = (state: { user: typeof initialState }) => state.user;