
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isActive: false,
    isRefresh: true,
    debitorBorcTrigger: false
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        // Notification badge update
        handleUpdateNotifyBadge: (state, { payload }) => {
            state.isActive = payload;
            state.isRefresh = false;
            return state;
        },
        // login olanda sehife refresh olunmuyubsa loginde set elemek ucun
        handleUpdateNotifyIsRefresh: (state, { payload }) => {
            state.isRefresh = payload;
        },

        changeDebitorBorcTrigger: (state, { payload }) => {
            state.debitorBorcTrigger = payload;
            return state;
        },
    }

})

export const {
    handleUpdateNotifyBadge,
    handleUpdateNotifyIsRefresh,
    changeDebitorBorcTrigger
} = notificationSlice.actions;

export const notificationSelector = (state: { notification: typeof initialState }) => state.notification;