export const ROUTES = {

    PARTNER_REGISTRATION: '/partnerRegistration',
    REGISTER_SUCCESS: '/partnerRegistration/registerSuccess',
    CONFIRM_OTP: '/ConfirmOtp',
    PARTNER_MAIN_LOGIN: '/partner_main_login',

    PASSWORD_RECOVERY: "/password_recovery",
    PASSWORD_SEND_CHOICE: "/password_send_choice",
    PASSWORD_CHECK_OTP: "/password_check_otp",
    PASSWORD_RESET: "/password_reset",

    PARTNER_FIND: '/partner_find',
    PARTNER_INFO: '/partner_find/confirm_info',
    PDF_SHOW: '/partner_find/document_show',
    PARTNER_SEND_NUMBER: '/partner_find/send_number',
    PARTNER_CODE_VERTIFCATION: '/partner_find/code_vertifcation',
    SIGNATURE_SUCCESS: '/partner_find/signature_success',

    USER_SETTINGS: '/user/settings',
    RATING_INFO: '/user/rating_info',

    MAIN: '/'

}