
import { Spin } from "antd";
import "./index.scss";

const Loading = () => {
    
    return (
        <div className="LOADING_COMPONENT body" >
            <Spin tip="Yüklənir..." size="large" />
        </div>
    )
}

export default Loading;
