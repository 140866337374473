import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isRegister: false,
}

export const succesRegisterSlice = createSlice({
    name: 'successRegister',
    initialState,
    reducers: {
        changeRegisterStatus: (state) => {
            state.isRegister = true
        },
    }

})

export const { changeRegisterStatus } = succesRegisterSlice.actions;

export const successRegisterSelector = (state:{successRegister:typeof initialState} ) => state.successRegister;