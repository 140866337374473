
import { Suspense, lazy, } from "react";
import { Route, Routes } from "react-router-dom";

import Loading from "components/Loading";
import { ROUTES } from "static";
import { PrivateRoute } from "./PrivateRoute";

const PartnerRegistration = lazy(() => import('pages//PartnerRegistration'));
const RegisterSuccess = lazy(() => import('pages/PartnerRegistration/components/RegisterSuccess'));
const PartnerMainLogin = lazy(() => import('pages/PartnerMainLogin'));
const PasswordRecovery = lazy(() => import('pages/ForgotPassword/components/Recovery'));
const PasswordSendChoice = lazy(() => import('pages/ForgotPassword/components/SendChoice'));
const PasswordCheckOtp = lazy(() => import('pages/ForgotPassword/components/CheckOtp'));
const PasswordReset = lazy(() => import('pages/ForgotPassword/components/ResetPassword'));

const Companies = lazy(() => import('pages/Companies'));
const NotFoundPage = lazy(() => import('pages/NotFound'));
const PartnerInfo = lazy(() => import('pages/PartnerFind/components/PartnerInfo'));
const PdfShow = lazy(() => import('pages/PartnerFind/components/PdfShow'));
const PartnerSendNumber = lazy(() => import('pages/PartnerFind/components/PartnerSendNumber'));
const PartnerNumberVertifcation = lazy(() => import('pages/PartnerFind/components/PartnerCodeVertifcation'));
const SignatureSuccess = lazy(() => import('pages/PartnerFind/components/SignatureSuccess'));
const ConfirmOtp = lazy(() => import('pages/ConfirmOtp'));

const Setting = lazy(() => import('pages/User/Setting'));

const RouteList = () => {

    return (
        <Suspense fallback={<Loading />} >
            <Routes>
                {/* -------------Login Routes--------------------- */}
                <Route path={ROUTES.PARTNER_REGISTRATION} element={<PartnerRegistration />} />
                <Route path={ROUTES.REGISTER_SUCCESS} element={<RegisterSuccess />} />
                <Route path={ROUTES.PARTNER_MAIN_LOGIN} element={<PartnerMainLogin />} />
                <Route path={ROUTES.PASSWORD_RECOVERY} element={<PasswordRecovery />} />
                <Route path={ROUTES.PASSWORD_SEND_CHOICE} element={<PasswordSendChoice />} />
                <Route path={ROUTES.PASSWORD_CHECK_OTP} element={<PasswordCheckOtp />} />
                <Route path={ROUTES.PASSWORD_RESET} element={<PasswordReset />} />
                <Route path={ROUTES.CONFIRM_OTP} element={<ConfirmOtp />} />

                {/* Partnyroun tap */}
                <Route path={ROUTES.PARTNER_INFO} element={<PartnerInfo />} />
                <Route path={ROUTES.PDF_SHOW} element={<PdfShow />} />
                <Route path={ROUTES.PARTNER_SEND_NUMBER} element={<PartnerSendNumber />} />
                <Route path={ROUTES.PARTNER_CODE_VERTIFCATION} element={<PartnerNumberVertifcation />} />
                <Route path={ROUTES.SIGNATURE_SUCCESS} element={<SignatureSuccess />} />

                {/* Pages */}
                <Route element={<PrivateRoute />}  >
                    <Route  path={ROUTES.MAIN} element={<Companies />} />
                    <Route  path={ROUTES.USER_SETTINGS} element={<Setting />} />
                </Route>

                {/* 404 not found */}
                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    )
}


export default RouteList;