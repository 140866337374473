
import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { YMInitializer as YM } from 'react-yandex-metrika';
import { Button, notification } from "antd";
import packageJson from '../package.json';
import { ArrowRightOutlined } from "@ant-design/icons";

import agent from "services";
import { userSelector, refreshFilledData } from "store/UserSlice";
import { notificationSelector, handleUpdateNotifyBadge } from "store/NotificationSlice";
import RouteList from "routes";
import { cleanCache, isCheckProduction } from "helpers";
import { ROUTES } from "static";
import Music from "assets/files/notify-sound.mp3";

function App() {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuth, userData: { partnerId, email } } = useSelector(userSelector);
  const { isRefresh } = useSelector(notificationSelector);
  const [token] = useState(localStorage.getItem('ExternalAgentToken'))
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [api, contextHolder] = notification.useNotification();

  const audio = new Audio(Music);

  const onNavigateTab = () => {

    navigate(ROUTES.USER_SETTINGS, {
      state: {
        activeTab: 3
      }
    });

  };

  const openNotificationWithIcon = (type: string) => {
    api[type]({
      message: <span style={{ fontWeight: 600 }}  >Nəzərinizə!</span>,
      description: (
        <>
          <p style={{ marginBottom: "5px", fontSize: "13.5px" }} >
            Hörmətli müştəri! Portfelinizdə olan şirkətlərin məlumatını istədiyiniz formada izləmək imkanınız mövcuddur.
            Bunun üçün  <strong>"Tənzimləmələr" </strong>səhifəsinə daxil olaraq  <strong>"Məlumatlandırma forması"</strong> seçimini etməlisiniz.
            Bununla da, şirkətlər haqqında məlumatı istədiyiniz formada ala biləcəksiniz.</p>
          <div className='notification-contracts' > <Button type="primary" className="outlined" onClick={onNavigateTab}>Səhifəyə keçid <ArrowRightOutlined /></Button> </div>

        </>
      ),
      style: {
        width: 500,
      },
      duration: 0,
    });
  };

  useEffect(() => {
    if (!isAuth && token) {
      agent.Partner.Get()
        .then((res) => {
          dispatch(refreshFilledData(res))
        })
    }
  }, [dispatch, isAuth, token])

  useEffect(() => {
    if (isAuth) {

      Sentry.configureScope((scope) => {
        scope.setUser({ id: partnerId!, email: email! });
      });

      const newConnection = new HubConnectionBuilder()
        .withUrl(import.meta.env.VITE_API_TEST_URL + "cpaagentapi/NotificationUserHub?userId=" + partnerId)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      newConnection.serverTimeoutInMilliseconds = 100000;
      setConnection(newConnection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  useEffect(() => {
    if (connection) {
      connection.on("sendToUser", () => {
        audio.play();
        dispatch(handleUpdateNotifyBadge(true))
      });

      const connectionStart = async () => {
        await connection.start().then(() => {
          // console.log("connected")
          connection.invoke("GetConnectionId").then(function () {
            // console.log("connectionId", connectionId)
          })
        }).catch(function (err) {
          return console.error(err.toString());
        })

      }
      connectionStart()
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection])

  useEffect(() => {
    const handleUpdateNotify = async () => {
      await agent.Notification.GetCounts()
        .then((res) => {
          dispatch(handleUpdateNotifyBadge((res.generalCount !== 0 || res.debitorCount !== 0) && true))
        })
    }

    if (isAuth && isRefresh) {
      handleUpdateNotify();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, isAuth, dispatch])

  useEffect(() => {

    if (isAuth) {
      agent.Notification.NotificationAction()
        .then((res) => {
          if (res.loginCount <= 3) {
            setTimeout(() => {
              openNotificationWithIcon('info');
            }, 600)
          }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  const version = packageJson.version;
  useEffect(() => {
    cleanCache(version);
  }, [version])

  return (
    <>
      {contextHolder}
      {
        isCheckProduction() &&
        <YM
          accounts={[91466657]}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version="2"
        />
      }

      <RouteList />
    </>

  );
}

export default App;
