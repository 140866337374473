

import axios, { AxiosRequestConfig } from "axios";
import * as Sentry from '@sentry/browser';
import { errorMsg, infoMsg, history } from "helpers";
import { ROUTES } from "static";



const http = axios.create();

http.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
http.defaults.headers.post['Content-Type'] = 'application/json';
http.defaults.headers.post['Accept'] = 'application/json';

const regularEndPoint = 'CpaAgent';
const identityEndPoint = 'IdentityAccount'


http.interceptors.request.use((config) => {
    const token = localStorage.getItem("ExternalAgentToken");
    if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

http.interceptors.response.use(
    async (res) => res,
    (err) => {
        const { status, data } = err.response;
        Sentry.captureException(err);
        switch (status) {
            case 400:
                // eslint-disable-next-line no-case-declarations
                const badRequestError = Object.keys(data).map((fieldName) => {
                    return data[fieldName]
                })
                errorMsg(badRequestError);
                break;
            case 401:
                localStorage.clear();
                history.push(ROUTES.PARTNER_MAIN_LOGIN);
                infoMsg('Sizin sesiyanın müddəti bitmişdir! Zəhmət olmasa, yenidən daxil olun!')
                break;

            case 404:
                errorMsg('Verdiyiniz sorğu movcud deyil! Zəhmət olmasa adminstratorla əlaqə saxlayasınız!');
                break;

            case 500:
                errorMsg('Serverdə xəta baş verdi!');
                break;
            default:
                break;
        }

        return Promise.reject(err);
    }
);


const requests = {
    get: async (url: string, config?: AxiosRequestConfig) => {
        const res = await http.get(url, config);
        return res.data;
    },
    post: async (url: string, data?, config?: AxiosRequestConfig) => {
        const res = await http.post(url, data, config);
        return res.data;
    },
    delete: async (url: string, config?: AxiosRequestConfig) => {
        const res = await http.delete(url, config);
        return res.data;
    },
}; 

const User = {
    partnerPhysicalCreate: (data) => requests.post(regularEndPoint + '/Security/RegisterPhysicalPartner', data),
    partnerLegalCreate: (data) => requests.post(regularEndPoint + '/Security/RegisterLegalPartner', data),
    confirmOtp: (otpCode) => requests.post(regularEndPoint + '/Security/ConfirmOtp', { otpCode }),
    partnerLogin: (data) => requests.post(regularEndPoint + '/Security/LoginPartner', data),
    getUserByUsername: (username) => requests.get(identityEndPoint + '/GetUserByName?UserName=' + username),
    ChangePassword: (data) => requests.post(regularEndPoint + '/Security/ChangePassword', data),
    Logout: () => requests.post(identityEndPoint + '/Logout'),

    GetUserByUserName: (userName) => requests.get(regularEndPoint + '/Security/GetUserByUserName?username=' + userName),
    SendOtpChoice: (data) => requests.post(regularEndPoint + '/Security/SendOtpByChoise', data),
    CheckOtp: (data) => requests.post(regularEndPoint + '/Security/CheckOtp', data),
    ResetPassword: (data) => requests.post(regularEndPoint + '/Security/ChangePasswordOtp', data),
};

const Company = {
    All: (data) => requests.post(regularEndPoint + '/ExternalPartner/Contracts', data),
    GetSupplements: (data) => requests.post(regularEndPoint + '/ExternalPartner/ContractAdditions', data),
}
const PartnerFind = {
    checkPartnerCompany: (data) => requests.post(regularEndPoint + '/ExternalCompany/CheckPartnerCompany', data),
    documentShow: (data) => requests.post(regularEndPoint + '/ExternalCompany/PartnerCompanyDocument', data),
    assignPartner: (data) => requests.post(regularEndPoint + "/ExternalCompany/AssignPartner", data),
    checkStatus: (data) => requests.post(regularEndPoint + "/ExternalCompany/CheckStatusAsanVerificationCode", data)

}
const Partner = {
    Get: () => requests.get(regularEndPoint + '/ExternalPartner/Get'),
    Update: (data) => requests.post(regularEndPoint + "/ExternalPartner/UpdateExternalPartner", data),
}

const Notification = {
    GetSettings: () => requests.get(regularEndPoint + '/ExternalPartnerNotification/GetNotificationSettings'),
    UpdateSettings: (data) => requests.post(regularEndPoint + "/ExternalPartnerNotification/NotifyMe", data),
    GetAll: (data) => requests.post(regularEndPoint + "/ExternalPartnerNotification/GetAll", data),
    GetCounts: () => requests.get(regularEndPoint + "/ExternalPartnerNotification/GetNewNotificationsCount"),
    ResetCounts: (data) => requests.post(regularEndPoint + "/ExternalPartnerNotification/ResetNotificationCount", data),
    UpdateAsReaded: ({ notificationId }) => requests.post(regularEndPoint + "/ExternalPartnerNotification/UpdateNotificationAsReaded", { notificationId }),
    NotificationAction: () => requests.get(regularEndPoint + '/ExternalPartner/GetPartnerActions'),

}
const Rating = {
    GetRatingData: (partnerId) => requests.get(regularEndPoint + '/Reyting/GetPartnerReytingsData?partnerId=' + partnerId),
    GetAllContractsRating: (partnerId) => requests.get(regularEndPoint + '/Reyting/GetAllContractsWithReyting?partnerId=' + partnerId),
    GetParticipateContractsRating: (partnerId) => requests.get(regularEndPoint + '/Reyting/GetUsedContractsForReyting?partnerId=' + partnerId),
    GetNotParticipateContractsRating: (partnerId) => requests.get(regularEndPoint + '/Reyting/GetAvilableContractsForReyting?partnerId=' + partnerId),
    GetPendingContractsRating: (partnerId) => requests.get(regularEndPoint + '/Reyting/GetWaitingContractsForReyting?partnerId=' + partnerId),
    GetContractSupplements: (data) => requests.post(regularEndPoint + '/Reyting/GetContractAdditions', data),
    SendNotParticipateContractsRating: (data) => requests.post(regularEndPoint + '/Reyting/SendContractsForReyting', data),
}

const Export = {
    All: (data) => requests.post(regularEndPoint + '/ExternalPartner/ExportContractsToExcel', data, {
        responseType: 'arraybuffer'
    }),
    GetSupplements: (data) => requests.post(regularEndPoint + '/ExternalPartner/ExportContractAdditionsToExcel', data, {

        responseType: 'arraybuffer'
    }),
}
const agent = {
    User,
    Company,
    PartnerFind,
    Partner,
    Notification,
    Rating,
    Export
}

export default agent;


